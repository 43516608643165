
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { PartRequest, PartRequestFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";
import PartRequestCancelButton from "./details/PartRequestCancelButton.vue";
import PartRequestToggleFullfilledButton from "./details/PartRequestToggleFullfilledButton.vue";
import PartRequestOrderButton from "./details/PartRequestOrderButton.vue";
import PartRequestStatusBadge from "./details/PartRequestStatusBadge.vue";
import PartRequestStatusProgressButtonGroup from './details/PartRequestStatusProgressButtonGroup.vue';

// type PartRequestRow = PartRequest & { _toggled?: boolean };

@Component({
  components: {
    PartRequestCancelButton,
    PartRequestToggleFullfilledButton,
    PartRequestOrderButton,
    PartRequestStatusBadge,
    PartRequestStatusProgressButtonGroup
  },
})
export class PartRequestList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: PartRequest[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => false })
  private itemsPerPageSelect!: boolean;

  @Prop({ default: () => [] })
  private excludeColumns!: Array<string>;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: PartRequestFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  collapseDuration = 0;

  activeMap = new Map<number, boolean>();

  details = [];

  fields: CDataTableFields = [
    { key: "checkbox", label: "", filter: false, sorter: false },
    { key: "part_id", label: "Part", filter: false, sorter: true },
    { key: "manufacturer", label: "Manufacturer", filter: false },
    { key: "tech_name", label: "Tech", filter: false, sorter: false },
    { key: "category", label: "Category", filter: false },
    { key: "quantity", label: "Qty", filter: false, sorter: false },
    // { key: "fulfilled", label: "Fulfilled", filter: false, sorter: false },
    // { key: "on_order", label: "On order", filter: false, sorter: false },
    { key: "taskNumber", label: "Task #", filter: false, sorter: false },
    // { key: "mr_id", label: "MR", filter: false, sorter: false },
    { key: "created_at", label: "Made on", filter: false },
    { key: "updated_at", label: "Last update", filter: false, sorter: true },
    { key: "action", label: "", filter: false, sorter: false, _style: "width:190px", },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];

  selected: number[] = [];

  get columns(): CDataTableFields {
    if (!this.excludeColumns.length) {
      return this.fields;
    }

    return this.fields.filter(
      (field) => this.excludeColumns.indexOf(field.key) === -1
    );
  }

  toggleDetails({ id }: PartRequest) {
    // this.$set(this.items[item.id], "_toggled", !item._toggled);
    // console.log('toggle', id, !this.isActive(id))
    this.activeMap.set(id, !this.isActive(id));
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }

  isActive(id: number) {
    if (!this.activeMap.has(id)) {
      return false;
    }

    return !!this.activeMap.get(id);
  }

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: PartRequestFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: PartRequest) {
    // this.$router.push(`/partRequest/${item.id}`);
  }

  onFilteredItemChange() {
    this.selected = [];
  }

  onCheckAll() {
    this.selected = this.items.map((item) => +item.id);
  }

  onUnCheckAll() {
    this.selected = [];
  }

  onRefresh() {
    this.$emit("data-refresh");
  }

  onNotSupported() {
    alert('Not supported yet');
  }
}

export default PartRequestList;
