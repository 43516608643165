
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartRequestInterface, PartRequest } from "@/store/modules";

@Component
export class PartRequestOrderButton extends Vue {
  @Prop(PartRequest)
  private record!: PartRequestInterface;

  @Prop(Boolean)
  loading = false;

  @Prop(String)
  size!: string;

  @Prop(String)
  variant!: string;

  @Prop({ type: Boolean, default: () => false })
  disabled!: boolean;

  savingText = "Ordering";

  // get isVoided() {
  //   // return false;
  //   return this.record.voided;
  // }

  get text() {
    // return "Order"
    return this.record.ordered ? "Ordered" : "Order";
  }

  get color() {
    if(this.variant) {
      return this.variant;
    }
    return this.record.ordered ? "danger" : "success";
  }

  get action () {
    return this.record.ordered ? "Cancel" : "Order";
  }

  onClick() {
    // const {part} = this.record;
    console.log(this.record);
    this.$bvModal
      .msgBoxConfirm(
        `Are you sure you want to ${this.action.toLowerCase()} ${
          this.record.part ? this.record.part.name : ""
        }?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      )
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.runAction());
      });
  }

  async runAction() {
    try {
      await PartRequest.dispatch("order", {
        id: this.record.id,
      });
      // console.log('ORDERBTN AFTER DISPATCH')
      this.$emit("saved");
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default PartRequestOrderButton;
