
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartRequestInterface, PartRequest } from "@/store/modules";

@Component
export class PartRequestCancelButton extends Vue {
  @Prop(PartRequest)
  private record!: PartRequestInterface;

  @Prop(Boolean)
  loading = false;

  savingText = "Cancelling";

  // get isVoided() {
  //   // return false;
  //   return this.record.voided;
  // }

  get text() {
    return "Cancel";
  }

  onClick() {
    // const {part} = this.record;
    console.log(this.record);
    this.$bvModal
      .msgBoxConfirm(
        `Are you sure you want to ${this.text} this part request?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      )
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.runAction());
      });
  }

  async runAction() {
    try {
      await PartRequest.dispatch("cancel", {
        id: this.record.id,
      });
      this.$emit('saved');
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default PartRequestCancelButton;
