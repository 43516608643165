
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartRequestInterface, PartRequest } from "@/store/modules";

@Component
export class PartRequestToggleFullfilledButton extends Vue {
  @Prop(PartRequest)
  private record!: PartRequestInterface;

  @Prop(Boolean)
  loading = false;

  @Prop(String)
  size!: string;

  @Prop(String)
  variant!: string;

  @Prop({ type: Boolean, default: () => false })
  disabled!: boolean;

  savingText = "Fulfilling";

  get btnSize() {
    return this.size;
  }

  // get isVoided() {
  //   // return false;
  //   return this.record.voided;
  // }

  get color() {
    if (this.variant) {
      return this.variant;
    }
    return this.record.fulfilled ? "danger" : "info";
  }

  get text() {
    // return "Fulfill";
    // return this.record.fulfilled ? "Unfulfill" : "Fulfill";
    return this.record.fulfilled ? "Fulfilled" : "Fulfill";
  }

  get action() {
    return this.record.fulfilled ? "Unfulfill" : "Fulfill";
  }

  onClick() {
    // const {part} = this.record;
    console.log(this.record);
    this.$bvModal
      .msgBoxConfirm(
        `Are you sure you want to ${this.action.toLowerCase()} this part request?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        }
      )
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.runAction());
      });
  }

  async runAction() {
    try {
      await PartRequest.dispatch("fulfill", {
        id: this.record.id,
      });

      this.$emit("saved");
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default PartRequestToggleFullfilledButton;
