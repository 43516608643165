
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartRequestInterface, PartRequest } from "@/store/modules";

@Component
class PartRequestStatusBadge extends Vue {
  @Prop(PartRequest)
  private record!: PartRequestInterface;

  text() {
    switch (true) {
      case this.record.fulfilled !== 0:
        return "Fulfilled";
      case this.record.ordered !== 0:
        return "Ordered";
    }

    return "Pending";
  }

  color() {
    switch (true) {
      case this.record.fulfilled !== 0:
        return "success";
      case this.record.ordered !== 0:
        return "info";
    }

    return "warning";
  }
}

export default PartRequestStatusBadge;
