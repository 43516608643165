
import { Component, Prop, Vue } from "vue-property-decorator";
import PartRequestCancelButton from "./PartRequestCancelButton.vue";
import PartRequestToggleFullfilledButton from "./PartRequestToggleFullfilledButton.vue";
import PartRequestOrderButton from "./PartRequestOrderButton.vue";
import { PartRequestInterface, PartRequest } from "@/store/modules";

const defaultStates = () => ({
  request: {
    disabled: true,
    variant: "secondary",
  },
  order: {
    disabled: false,
    variant: "secondary",
  },
  fulfill: {
    disabled: false,
    variant: "secondary",
  },
  voided: {
    disabled: false,
    variant: "secondary",
  },
});

@Component({
  components: {
    PartRequestCancelButton,
    PartRequestToggleFullfilledButton,
    PartRequestOrderButton,
  },
})
export default class PartRequestStatusProgressButtonGroup extends Vue {
  @Prop({ type: Function, default: () => {} })
  onRefresh!: () => any;

  @Prop(PartRequest)
  item!: PartRequestInterface;

  /*states = {
    request: {
      disabled: true,
      variant: "secondary",
    },
    order: {
      disabled: false,
      variant: "secondary",
    },
    fulfill: {
      disabled: false,
      variant: "secondary",
    },
  };*/

  get states() {
    const state = { ...defaultStates() };
    // @todo. no voided state found in payload. using false as placeholder when api implements it
    const [ordered, fulfilled, voided] = [
      this.item.ordered,
      this.item.fulfilled,
      false,
    ].map((s) => Boolean(s));
    if (!ordered && !fulfilled && !voided) {
      state.request.variant = "secondary";
      state.order.variant = "info";
      state.fulfill.variant = "info";
      return state;
    }

    state.request.variant = "success";
    if (ordered && !fulfilled && !voided) {
      state.order.variant = "success";
      state.fulfill.variant = "info";
      return state;
    }

    if (ordered && fulfilled && !voided) {
      state.order.variant = "success";
      state.fulfill.variant = "success";
      return state;
    }

    if (!ordered && fulfilled && !voided) {
      state.order.variant = "info";
      state.fulfill.variant = "success";
      return state;
    }

    return state;
  }
}
